#childs_first_name {
    margin-bottom: 50px;
}

form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

input {
    background: none;
    border: solid #792839;
    border-width: 0 0 1px 0;
    margin: 5px 10px;
    font-family: "Julius Sans One", sans-serif;
    font-weight: 700;
    color: #792839;
    font-size: 20px;
    padding: 10px 5px;
    border-radius: 8px 8px 0 0;
    background: rgba(121, 40, 57, 0.4);
}

input[type=text] {
    width: 250px;
}

input[type=number] {
    width: 40px;
    padding: 10px 20px;
    border-radius: 0;
    border: solid 1px #ffffff;
}

input:focus {
    outline: none;
    background: rgba(217, 149, 167, 0.4) !important;
}

.input-group-col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.input-group-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

label {
    color: #792839;
    padding: 20px 10px 10px 10px;
    font-family: "quiche-sans", sans-serif;
    font-weight: 600;
    letter-spacing: 1.5px;
    font-size: 18px;
}

.form-group-row {
    margin-top: 50px;
    padding-top: 20px;
    border-top: 1px rgba(255, 255, 255, 0.25) solid;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 600px;
}

.form-group-col {
    margin-top: 50px;
    padding-top: 20px;
    height: 200px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    width: 600px;
}

.form-group-wrap-row {
    margin-top: 50px;
    padding-top: 20px;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 600px;
    border-top: 1px solid;
}


button {
    background-image: linear-gradient(92.88deg, #d995a7 9.16%, rgb(195, 133, 149) 43.89%, rgb(189, 129, 145) 64.72%);
    border-radius: 8px;
    border: solid #792839 2px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    flex-shrink: 0;
    font-family: "Julius Sans One", sans-serif;
    font-size: 16px;
    font-weight: bold;
    height: 4rem;
    padding: 0 1.6rem;
    text-align: center;
    transition: all .5s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-top: 50px;
}

button:hover {
    box-shadow: rgb(121, 40, 57) 0 1px 30px;
    transition-duration: .1s;
}

button:active {
    transform: scale(0.95);
}

h1 {
    font-family: "Julius Sans One", sans-serif;
    color: #792839;
}

textarea {
    width: 580px;
    background: rgba(121, 40, 57, 0.4);
    border: solid white;
    border-width: 0 0 1px 0;
    font-family: "Julius Sans One", sans-serif;
    font-weight: 700;
    color: #792839;
    font-size: 20px;
    padding: 10px;
}

textarea:focus {
    outline: none;
    background: rgba(217, 149, 167, 0.4) !important;
}

input[type=checkbox] {
    width: 30px;
    height: 30px;
    accent-color: #792839;
}

@media (max-width: 320px) {
    html, body, root {
        width: 100vw;
        overflow-x: hidden !important;
        overflow-y: scroll !important;
    }

    .form-group-row {
        margin-top: 50px;
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 300px;
    }

    .rsvp-from-wrapper {
        width: calc(100% - 80px);
        position: relative;
        margin: 0 auto 150px auto;
        padding: 20px;
        top: 20px;
    }

    .form-group-wrap-row {
        margin-top: 50px;
        padding-top: 20px;
        justify-content: space-between;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 300px;
    }


    textarea {
        width: 80%;
        margin: auto;
    }

    input[type=text] {
        width: 90%;
    }

    .input-group-col {
        width: 80%;
        margin: auto;
        justify-content: space-between;
    }

    .input-group-col:has(textarea) {
        width: 100% !important;
    }

    .input-group-row {
        width: 80%;
        margin: auto;
        justify-content: space-between;
    }

    label {
        font-size: 14px;
        width: 80%;
    }

    .form-group-wrap-row {
        width: 100%;
        margin: 20px auto;
        justify-content: center;
    }
}

@media (max-width: 768px) and (min-width: 321px) {
    html, body, root {
        width: 100vw;
        overflow-x: hidden !important;
        overflow-y: scroll !important;
    }

    .page-title {
        width: 85%;
        margin: 50px auto;
    }

    .form-group-row {
        margin-top: 50px;
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100% !important;
    }

    .form-group-col {
        width: 100% !important;
    }

    .rsvp-from-wrapper {
        width: calc(100% - 70px);
        position: relative;
        margin: 0 auto 150px auto;
        padding: 20px;
        top: 20px;
    }

    .form-group-wrap-row {
        margin-top: 50px;
        padding-top: 20px;
        justify-content: space-between;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 300px;
    }

    textarea {
        width: 90%;
        margin: auto;
    }

    input[type=text] {
        width: 90%;
    }

    .input-group-col {
        width: 100%;
        margin: auto;
        justify-content: space-between;
    }

    .input-group-row {
        width: 100% !important;
        margin: auto;
        justify-content: space-between;
    }

    label {
        font-size: 14px;
    }

    .form-group-wrap-row {
        width: 100%;
        margin: 20px auto;
        justify-content: center;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {

    .rsvp-from-wrapper {
        width: 70%
    }
}

.details-wrapper {
    width: 80%;
    padding: 5%;
    border: 1px solid black;
    border-radius: 18px;
    margin-top: 20px;
}