@media (max-width: 320px) {
    .gift{
        margin: auto;
        width: 500px;
        transform:scale(0.98)
    }
    .gift-title{
        margin:30px auto;
        width:500px;
        text-align:center;
    }
}

@media (max-width: 768px) and (min-width: 321px) {
    .gift{
        margin: auto;
        width: 321px;
        transform:scale(0.98)
    }
    .gift-title{
        margin:30px auto;
        width:321px;
        text-align:center;
    }
    .gift-frame{
        width: 321px;
        height: 1000px;
    }
}

@media (min-width: 769px){
    .gift{
        margin: auto;
        width: 500px;
        transform:scale(0.98)
    }
    .gift-title{
        margin:30px auto;
        width:500px;
        text-align:center;
    }
    .gift-frame{
        width: 500px;
        height: 1000px;
    }
}
