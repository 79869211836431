:root {
    --carousel-translateZ-tablet: 340px;
}

.page-title {
    margin: 20px 0 30px 0;
    text-align: center;
    color: #792839;;
    font-size: calc(10px + 5vmin);
    font-family: "AmsterdamOne", sans-serif;
}

/* Container and Carousel adjustments */
.container {
    margin: 150px auto;
    width: calc(364.8px / 1.99);
    height: calc(547.2px / 1.99);
    position: relative;
    perspective: 1000px;
}

h2{
    font-family: "AmsterdamOne", sans-serif;
    text-align: center;
    color: #792839;;
}
li > p{
    font-family: "quiche-sans", sans-serif;
    text-align: center;
}

ul{
    padding: 0;
}

li{
    list-style-type: none;
    margin-top: 10px;
}

li > p >  button {
    width: 100px;
    text-align: center;
    font-family: "quiche-sans", sans-serif !important;
    font-size: 14px !important;
    padding: 0;
    height: 40px;
    margin-top: 0;
    margin-left: 10px;
}

#carousel {
    width: 100%;
    height: 100%;
    position: absolute;
    transform-style: preserve-3d;
    animation: rotation 120s infinite linear;
    transform-origin: center center;
}

#carousel figure {
    display: block;
    position: absolute;
    width: calc(364.8px / 1.99);
    height: calc(547.2px / 1.99);
    background: #000000;
    overflow: hidden;
    border-radius: 15px;
    border: solid 2px #792839;
    top: 20px;
}

#carousel figure:nth-child(1) {
    transform: rotateY(0deg) translateZ(500px);
}
#carousel figure:nth-child(2) {
    transform: rotateY(40deg) translateZ(500px);
}
#carousel figure:nth-child(3) {
    transform: rotateY(80deg) translateZ(500px);
}
#carousel figure:nth-child(4) {
    transform: rotateY(120deg) translateZ(500px);
}
#carousel figure:nth-child(5) {
    transform: rotateY(160deg) translateZ(500px);
}
#carousel figure:nth-child(6) {
    transform: rotateY(200deg) translateZ(500px);
}
#carousel figure:nth-child(7) {
    transform: rotateY(240deg) translateZ(500px);
}
#carousel figure:nth-child(8) {
    transform: rotateY(280deg) translateZ(500px);
}
#carousel figure:nth-child(9) {
    transform: rotateY(320deg) translateZ(500px);
}

#flipdown > div:nth-child(5) {
    display: none;
}
#flipdown > div:nth-child(6) {
    display: none;
}
#flipdown > div:nth-child(7) {
    display: none;
}

#flipdown > div:nth-child(8) {
    display: none;
}

#flipdown {
    width: fit-content !important;
    margin: auto;
}

/* Image adjustments */
img {
    height: auto;
    width: 100%;
    cursor: pointer;
    transition: all 0.5s ease;
}

img:hover {
    transform: scale(1.2, 1.2);
}

/* FlipDown countdown */
#flipdown > div:nth-child(5),
#flipdown > div:nth-child(6),
#flipdown > div:nth-child(7),
#flipdown > div:nth-child(8) {
    display: none;
}

.rotor-top,
.rotor-bottom,
.rotor-leaf-front,
.rotor-leaf-rear {
    font-family: "Julius Sans One", sans-serif;
}

.flipdown.flipdown__theme-dark .rotor-bottom, .flipdown.flipdown__theme-dark .rotor-leaf-rear, .flipdown.flipdown__theme-dark .rotor, .flipdown.flipdown__theme-dark .rotor-top, .flipdown.flipdown__theme-dark .rotor-leaf-front{
    background: #ffffff !important;
    color: #792839 !important;
}

.rotor-group-heading {
    font-family: "quiche-sans", sans-serif;
}

.rotor-group::after {
    background: white !important;
}
.rotor-group::before {
    background: white !important;
}

.rotor-group-heading::before {
    color: #792839 !important;
}

#flipdown {
    width: fit-content !important;
    margin: auto;
}

/* Keyframes for carousel rotation */
@keyframes rotation {
    from {
        transform: rotateY(0deg);
    }
    to {
        transform: rotateY(360deg);
    }
}

/* Breakpoints */

@media (max-width: 320px) {

    .container {
        width: 100%;
        height: calc(5472px / 8);
        overflow: hidden;
        margin: 0 auto;
    }

    #flipdown {
        height: fit-content;
        align-content: center;
    }

    #flipdown > div:nth-child(4) {
        padding-right: 0;
    }

    #carousel figure {
        width: calc(3648px / 19) !important;
        height: calc(5472px / 19) !important;
        top: 180px;
        overflow: hidden;
    }

    .page-title {
        font-size: calc(8px + 5vmin);
    }

    /* Adjust the translateZ to make the images closer */
    #carousel figure:nth-child(1) {
        transform: rotateY(0deg) translateZ(370px);
    }
    #carousel figure:nth-child(2) {
        transform: rotateY(40deg) translateZ(370px);
    }
    #carousel figure:nth-child(3) {
        transform: rotateY(80deg) translateZ(370px);
    }
    #carousel figure:nth-child(4) {
        transform: rotateY(120deg) translateZ(370px);
    }
    #carousel figure:nth-child(5) {
        transform: rotateY(160deg) translateZ(370px);
    }
    #carousel figure:nth-child(6) {
        transform: rotateY(200deg) translateZ(370px);
    }
    #carousel figure:nth-child(7) {
        transform: rotateY(240deg) translateZ(370px);
    }
    #carousel figure:nth-child(8) {
        transform: rotateY(280deg) translateZ(370px);
    }
    #carousel figure:nth-child(9) {
        transform: rotateY(320deg) translateZ(370px);
    }

    .page-title {
        font-size: calc(6px + 5vmin);
        margin: 50px 0;
    }

    #carousel figure {
        left: 10px;
        right: 10px;
    }

    #carousel {
        animation: rotation 60s infinite linear;
    }
}

/* Phone (portrait) */
@media (max-width: 767px)  and (min-width: 321px){

    .container {
        width: 100%;
        height: calc(5472px / 8);
        overflow: hidden;
        margin: 0 auto;
    }

    #flipdown {
        height: fit-content;
        align-content: center;
    }

    #flipdown > div:nth-child(4) {
        padding-right: 0;
    }

    #carousel figure {
        width: calc(3648px / 19) !important;
        height: calc(5472px / 19) !important;
        top: 180px;
        overflow: hidden;
    }

    .page-title {
        font-size: calc(8px + 5vmin);
    }

    /* Adjust the translateZ to make the images closer */
    #carousel figure:nth-child(1) {
        transform: rotateY(0deg) translateZ(370px);
    }
    #carousel figure:nth-child(2) {
        transform: rotateY(40deg) translateZ(370px);
    }
    #carousel figure:nth-child(3) {
        transform: rotateY(80deg) translateZ(370px);
    }
    #carousel figure:nth-child(4) {
        transform: rotateY(120deg) translateZ(370px);
    }
    #carousel figure:nth-child(5) {
        transform: rotateY(160deg) translateZ(370px);
    }
    #carousel figure:nth-child(6) {
        transform: rotateY(200deg) translateZ(370px);
    }
    #carousel figure:nth-child(7) {
        transform: rotateY(240deg) translateZ(370px);
    }
    #carousel figure:nth-child(8) {
        transform: rotateY(280deg) translateZ(370px);
    }
    #carousel figure:nth-child(9) {
        transform: rotateY(320deg) translateZ(370px);
    }

    .page-title {
        font-size: calc(6px + 5vmin);
        margin: 50px 0;
    }

    #carousel figure {
        left: 10px;
        right: 10px;
    }

    #carousel {
        animation: rotation 60s infinite linear;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    #carousel {
        width: 80%;
        position: relative;
        margin: 0 auto;
        height: calc(5472px / 13);
    }
    .container {
        width: 100%;
        height: calc(5472px / 13);
        overflow: hidden;
        margin: 0 auto;
    }

    #flipdown {
        width: fit-content !important;
    }

    #carousel figure {
        width: calc(3648px / 24) !important;
        height: calc(5472px / 25) !important;
        overflow: hidden;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        top:100px;
        transform-origin: center center;
    }

    .page-title {
        font-size: calc(8px + 5vmin);
    }

    /* Adjust the translateZ to make the images closer */
    #carousel figure:nth-child(1) {
        transform: rotateY(0deg) translateZ(var(--carousel-translateZ-tablet));
    }
    #carousel figure:nth-child(2) {
        transform: rotateY(40deg) translateZ(var(--carousel-translateZ-tablet));
    }
    #carousel figure:nth-child(3) {
        transform: rotateY(80deg) translateZ(var(--carousel-translateZ-tablet));
    }
    #carousel figure:nth-child(4) {
        transform: rotateY(120deg) translateZ(var(--carousel-translateZ-tablet));
    }
    #carousel figure:nth-child(5) {
        transform: rotateY(160deg) translateZ(var(--carousel-translateZ-tablet));
    }
    #carousel figure:nth-child(6) {
        transform: rotateY(200deg) translateZ(var(--carousel-translateZ-tablet));
    }
    #carousel figure:nth-child(7) {
        transform: rotateY(240deg) translateZ(var(--carousel-translateZ-tablet));
    }
    #carousel figure:nth-child(8) {
        transform: rotateY(280deg) translateZ(var(--carousel-translateZ-tablet));
    }
    #carousel figure:nth-child(9) {
        transform: rotateY(320deg) translateZ(var(--carousel-translateZ-tablet));
    }

    .page-title {
        font-size: calc(6px + 5vmin);
        margin: 50px 0;
    }

    #carousel figure {
        left: 10px;
        right: 10px;
    }

    #carousel {
        animation: rotation 60s infinite linear;
    }
}

@media (min-width: 1024px) and (max-width: 1439px) {
    #carousel {
        width: 80%;
        position: relative;
        margin: 0 auto;
        height: calc(5472px / 13);
    }
    .container {
        width: 100%;
        height: calc(5472px / 13);
        overflow: hidden;
        margin: 0 auto;
    }

    #flipdown {
        width: fit-content !important;
    }

    #carousel figure {
        width: calc(3648px / 24) !important;
        height: calc(5472px / 25) !important;
        overflow: hidden;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        top:100px;
        transform-origin: center center;
    }

    .page-title {
        font-size: calc(8px + 5vmin);
    }

    /* Adjust the translateZ to make the images closer */
    #carousel figure:nth-child(1) {
        transform: rotateY(0deg) translateZ(var(--carousel-translateZ-tablet));
    }
    #carousel figure:nth-child(2) {
        transform: rotateY(40deg) translateZ(var(--carousel-translateZ-tablet));
    }
    #carousel figure:nth-child(3) {
        transform: rotateY(80deg) translateZ(var(--carousel-translateZ-tablet));
    }
    #carousel figure:nth-child(4) {
        transform: rotateY(120deg) translateZ(var(--carousel-translateZ-tablet));
    }
    #carousel figure:nth-child(5) {
        transform: rotateY(160deg) translateZ(var(--carousel-translateZ-tablet));
    }
    #carousel figure:nth-child(6) {
        transform: rotateY(200deg) translateZ(var(--carousel-translateZ-tablet));
    }
    #carousel figure:nth-child(7) {
        transform: rotateY(240deg) translateZ(var(--carousel-translateZ-tablet));
    }
    #carousel figure:nth-child(8) {
        transform: rotateY(280deg) translateZ(var(--carousel-translateZ-tablet));
    }
    #carousel figure:nth-child(9) {
        transform: rotateY(320deg) translateZ(var(--carousel-translateZ-tablet));
    }

    .page-title {
        font-size: calc(6px + 5vmin);
        margin: 50px 0;
    }

    #carousel figure {
        left: 10px;
        right: 10px;
    }

    #carousel {
        animation: rotation 60s infinite linear;
    }
}