@import url("https://fonts.googleapis.com/css2?family=Julius+Sans+One&display=swap");
@import url("https://use.typekit.net/jks1eor.css");
@font-face {
    font-family: "AmsterdamOne";
    src: url("/public/assets/fonts/AmsterdamOne.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "quiche sans thin";
    src: url("/public/assets/fonts/quichesans-thin.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "quiche-sans", sans-serif;
    src: url("/public/assets/fonts/quichesans-regular.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

html {
    background-color: rgba(0, 0, 0, 0.8);
}

body {
    position: relative;
    min-height: 100vh;
    overflow-x: hidden; /* Ensure no overflow */
    background: rgba(255, 255, 255, 0.45);
}

body::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("/public/images/background4.png") no-repeat center center
    fixed;
    background-size: cover;
    overflow-x: hidden;
    filter: blur(1.5px);
    pointer-events: none;
    z-index: -1;
}

#root {
    width: 100%;
    overflow-y: scroll;
}

.card {
    width: 650px;
    position: relative;
    margin:0 auto 250px  auto;
    background: rgba(255, 255, 255, 0.81);
    /*background: linear-gradient(119deg, rgb(217, 149, 167) 0%, rgb(121, 40, 57) 81%);*/
    /*border: solid 3px #ffffff;
    box-shadow: rgba(121, 40, 57, 0.81) 0px 3px 8px; */
    box-shadow:rgba(121, 40, 57, 0.81) 0px 2px 4px 0px, rgba(217, 149, 167, 0.9) 0px 2px 16px 0px;
    border-radius: 15px;
    padding: 30px;
    top: 150px;
    text-align: center;
}

.language-selection > button {
    display: inline-block;
    margin: 20px;
    width: 200px;
}

@media (max-width: 425px) and (min-height: 320px) {
    .card{
        width: 70%;
    }

    .card > h1{
        font-size: 18px !important;
    }

    .card > button{
        height: 50px;
        width: 150px;
    }
}

